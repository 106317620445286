import { IAuthUser } from "./types";
import { UserPermission, UserRole } from "./acl";

export class User implements IAuthUser {
  readonly id: IAuthUser["id"];
  readonly accountableId: IAuthUser["accountableId"];
  readonly avatar: IAuthUser["avatar"];
  readonly avatar_thumb: IAuthUser["avatar_thumb"];
  readonly email: IAuthUser["email"];
  readonly name: IAuthUser["name"];
  readonly roles: IAuthUser["roles"];
  readonly permissions: IAuthUser["permissions"];
  constructor(data: any) {
    this.id = data.id;
    this.accountableId = data.accountable_id;
    this.avatar = data.avatar;
    this.avatar_thumb = data.avatar_thumb;
    this.email = data.email;
    this.name = data.name;
    this.roles = data.roles;
    this.permissions = data.permissions;
  }

  get isAdmin() {
    return this.hasRole(UserRole.Administrator);
  }

  get isCustomer() {
    return this.hasRole(UserRole.Customer);
  }

  get isCustomerTeam() {
    return this.hasRole(UserRole.CustomerTeam);
  }

  get isRecruiter() {
    return this.hasRole(UserRole.Recruiter);
  }

  get hasReportsCrossesPermission() {
    return this.hasPermission(UserPermission.ReportsCrosses);
  }

  hasRole(role: string) {
    return !!this.roles.includes(role as UserRole);
  }

  hasAnyRole(roles: string[]) {
    return roles.some((role) => this.hasRole(role));
  }

  hasAllRoles(roles: string[]) {
    return roles.every((role) => this.hasRole(role));
  }

  hasPermission(permission: string) {
    return !!this.permissions.includes(permission as UserPermission);
  }

  hasAnyPermission(permissions: string[]) {
    return permissions.some((permission) => this.hasPermission(permission));
  }

  hasAllPermissions(permissions: string[]) {
    return permissions.every((permission) => this.hasPermission(permission));
  }

  hasPermissionStartsWith(str: string) {
    return !!this.permissions.find((permission) => permission.startsWith(str));
  }

  hasPermissionEndsWith(str: string) {
    return !!this.permissions.find((permission) => permission.endsWith(str));
  }
}
