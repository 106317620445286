import { FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { useAuthContext } from "modules/auth/hooks";

const GuestRoute: FunctionComponent<RouteProps> = ({ children, ...rest }) => {
  const { user } = useAuthContext();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        ) : (
          children
        )
      }
    />
  );
};

export default GuestRoute;
