import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import Maintenance from "./Maintenance";
import { locales } from "./i18n";
// import reportWebVitals from "./reportWebVitals";

const MOUNT_NODE = document.getElementById("root");
const IN_MAINTENANCE_MODE = process.env.REACT_APP_IN_MAINTENANCE_MODE;

const render = () => {
  ReactDOM.render(
    <React.StrictMode>
      {IN_MAINTENANCE_MODE ? (
        <Maintenance messages={locales} />
      ) : (
        <App messages={locales} />
      )}
    </React.StrictMode>,
    MOUNT_NODE
  );
};

if ((module as any).hot && MOUNT_NODE) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  (module as any).hot.accept(["./i18n", "./routes"], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render();
  });
}

render();

// if (process.env.REACT_APP_MOCK_ENABLED) {
//   const { worker } = require("./__mocks__");
//   worker.start();
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
