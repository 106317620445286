import { SET_LOCALE, LanguageAction } from "./types";

// export set locale action
export function setLocale(locale: string): LanguageAction {
  return {
    type: SET_LOCALE,
    payload: { locale }
  };
}

// export change locale
export function changeLocale(locale: string): LanguageAction {
  localStorage.setItem("locale", locale);
  document.documentElement.lang = locale;
  return setLocale(locale);
}
