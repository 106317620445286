import { useContext } from "react";
import { useQuery } from "react-query";

import { User } from "./User";
import { AuthContext } from "./AuthContext";
import { IGetAuthenticatedUserQuery } from "./types";
import { getAuthenticatedUserRequest } from "./fetchers";

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const useLoginUser = () => {
  const { loginUser } = useContext(AuthContext);
  if (!loginUser) {
    throw new Error("You need to wrap your application with <AuthProvider>.");
  }
  return loginUser;
};

export const useLogoutUser = () => {
  const { logoutUser } = useContext(AuthContext);
  if (!logoutUser) {
    throw new Error("You need to wrap your application with <AuthProvider>.");
  }
  return logoutUser;
};

export const useAuthenticatedUserQuery = (): IGetAuthenticatedUserQuery => {
  // get cached user from local storage if exists
  const key = "authenticatedUser";

  const {
    data: response,
    isFetching,
    isLoading,
    status,
    error,
    refetch
  } = useQuery(key, getAuthenticatedUserRequest, {
    enabled: Boolean(localStorage.getItem("accessToken")),
    // initialStale: true,
    retry: false,
    // save user data to local storage
    onError: (error) => {
      localStorage.removeItem("accessToken");
      window.location.reload();
    }
  });

  return {
    user: response?.data && new User(response.data),
    isFetching,
    isLoading,
    error,
    status,
    refetch,
    key: key
  };
};
