import { createContext } from "react";

import { IAuthContext } from "./types";

/**
 * @see https://medium.com/@dai_shi/a-thought-on-react-context-default-value-fb3283cb5788
 */
export const AuthContext = createContext<IAuthContext>({
  user: undefined,
  error: undefined,
  isAuthenticating: false,
  isFetchingAccessToken: false,
  isFetchingAuthenticatedUser: false,
  loginUser: () => {
    throw new Error("You need to wrap your application with <AuthProvider>.");
  },
  logoutUser: () => {
    throw new Error("You need to wrap your application with <AuthProvider>.");
  }
});
