import { Space, Spin } from "antd";
import { FunctionComponent } from "react";

const FullPageLoader: FunctionComponent = (props) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Space size="middle">
        <Spin size="large" />
      </Space>
    </div>
  );
};

export default FullPageLoader;
