import { useState, Children, FunctionComponent, ReactElement } from "react";

import { useMutation, useQueryClient } from "react-query";
import { useHistory, useLocation } from "react-router-dom";

import { AuthContext } from "./AuthContext";
import { IAuthContext, IAuthProps } from "./types";
import { useAuthenticatedUserQuery } from "./hooks";
import { userLoginRequest, userLogoutRequest } from "./fetchers";

export const AuthProvider: FunctionComponent<IAuthProps> = ({
  children
}): ReactElement => {
  /**
   * @selectors
   */
  const history = useHistory();
  const location = useLocation();

  /**
   * @states
   */
  const [authenticationError, setAuthenticationError] = useState<
    string | undefined
  >(undefined);

  /**
   * @queries
   */
  const queryClient = useQueryClient();
  const loginMutation = useMutation(userLoginRequest);
  const logoutMutation = useMutation(userLogoutRequest);

  const { user, isLoading } = useAuthenticatedUserQuery();

  const loginUser = async (
    email: string,
    password: string,
    redirectTo?: string
  ) => {
    try {
      const response = await loginMutation.mutateAsync({
        email,
        password
      });
      // save access token to storage
      localStorage.setItem("accessToken", response.data.bearer);

      // reset user queries
      queryClient.resetQueries();

      // set history
      const { from } = (location.state as any) || {
        from: { pathname: redirectTo }
      };
      history.replace(from);
    } catch (error) {
      localStorage.removeItem("accessToken");
      setAuthenticationError("Sorry! credentials you've entered are not valid");
    }
  };

  const logoutUser = async (redirectTo?: string) => {
    try {
      await logoutMutation.mutateAsync();
      localStorage.removeItem("accessToken");

      // reset user queries
      queryClient.setQueryData("authenticatedUser", undefined);

      history.push(redirectTo || "/authenticate");
    } catch (error) {}
  };

  const authProvider: IAuthContext = {
    user,
    error: authenticationError,
    isAuthenticating: loginMutation.isLoading || isLoading,
    isFetchingAccessToken: loginMutation.isLoading,
    isFetchingAuthenticatedUser: isLoading,
    loginUser,
    logoutUser
  };

  return (
    <AuthContext.Provider value={authProvider}>
      {Children.only(children)}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
