import Axios, { AxiosError } from "axios";
import Qs from "qs";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000/api/v1";
const API_KEY = process.env.REACT_APP_API_KEY || "";

const Api = Axios.create({
  baseURL: process.env.REACT_APP_MOCK_ENABLED ? "/api" : API_URL,
  headers: {
    "Content-Type": "application/json",
    "Accept-Language":
      localStorage.getItem("locale") || document.documentElement.lang || "en",
    Accept: "application/json",
    "x-api-key": API_KEY
  },
  paramsSerializer: function (params) {
    return Qs.stringify(params, { arrayFormat: "comma" });
  },
  //   browser only: 'blob'
  responseType: "json" // default
});

Api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      // Add bearer header to request
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`
      };
    }
    return config;
  },
  (error) => Promise.reject(error)
);

Api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  (error: AxiosError) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response) {
      // handle standard http error codes
      // redirect user to error pages
      if ([403, 500, 503].includes(error.response.status)) {
        window.location.href = `/${error.response.status}`;
      }

      if (error.response.status === 401) {
        localStorage.removeItem("authenticatedUser");
        localStorage.removeItem("accessToken");
        window.location.href = `/authenticate`;
        return;
      }

      return Promise.reject(error.response);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log("Error", error.message);
    }
    return Promise.reject(error);
  }
);

export default Api;
