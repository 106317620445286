import { IAuthUser } from "modules/auth/types";
import { UserRole } from "modules/auth/acl";

export interface IRoute {
  key: string;
  path: string;
  mustBeAuthenticated?: boolean;
  mustBeUnauthenticated?: boolean;
  roles?: IAuthUser["roles"];
  loader: () => Promise<
    React.ComponentType<any> | { default: React.ComponentType<any> }
  >;
}

export interface IRoutes {
  [key: string]: IRoute;
}

const routes: IRoutes = {
  home: {
    key: "/",
    path: "/",
    mustBeAuthenticated: true,
    loader: () => import("./pages")
  },
  login: {
    key: "/authenticate",
    path: "/authenticate",
    mustBeUnauthenticated: true,
    loader: () => import("./pages/authentication/login")
  },
  management: {
    key: "/management",
    path: "/management",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management"),
    roles: [UserRole.Administrator]
  },
  campaigns: {
    key: "/management/campaigns",
    path: "/management/campaigns",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/campaigns/list"),
    roles: [UserRole.Administrator]
  },
  createCampaign: {
    key: "/management/campaigns/create",
    path: "/management/campaigns/create",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/campaigns/create"),
    roles: [UserRole.Administrator]
  },
  editCampaign: {
    key: "/management/campaigns/:id/edit",
    path: "/management/campaigns/:id/edit",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/campaigns/edit"),
    roles: [UserRole.Administrator]
  },
  showCampaign: {
    key: "/management/campaigns/:id",
    path: "/management/campaigns/:id",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/campaigns/show"),
    roles: [UserRole.Administrator]
  },
  participants: {
    key: "/management/participants",
    path: "/management/participants",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/participants/list"),
    roles: [UserRole.Administrator, UserRole.Recruiter]
  },
  createParticipant: {
    key: "/participants/create",
    path: "/management/participants/create",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/participants/create"),
    roles: [UserRole.Administrator, UserRole.Recruiter]
  },
  editParticipant: {
    key: "/management/participants/:id/edit",
    path: "/management/participants/:id/edit",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/participants/edit"),
    roles: [UserRole.Administrator]
  },
  showParticipant: {
    key: "/management/participants/:id",
    path: "/management/participants/:id",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/participants/show"),
    roles: [UserRole.Administrator, UserRole.Recruiter]
  },
  organizations: {
    key: "/management/organizations",
    path: "/management/organizations",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/organizations"),
    roles: [UserRole.Administrator]
  },
  contacts: {
    key: "/management/contacts",
    path: "/management/contacts",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/contacts"),
    roles: [UserRole.Administrator]
  },
  recruiters: {
    key: "/management/recruiters",
    path: "/management/recruiters",
    mustBeAuthenticated: true,
    loader: () => import("./pages/management/recruiters"),
    roles: [UserRole.Administrator]
  },
  administration: {
    key: "/administration",
    path: "/administration",
    mustBeAuthenticated: true,
    loader: () => import("./pages/administration"),
    roles: [UserRole.Administrator]
  },
  users: {
    key: "/administration/users",
    path: "/administration/users",
    mustBeAuthenticated: true,
    loader: () => import("./pages/administration/users"),
    roles: [UserRole.Administrator]
  },
  apkReleases: {
    key: "/administration/apk-releases",
    path: "/administration/apk-releases",
    mustBeAuthenticated: true,
    loader: () => import("./pages/administration/apk-releases"),
    roles: [UserRole.Administrator]
  },
  profile: {
    key: "/account/profile",
    path: "/account/profile",
    mustBeAuthenticated: true,
    loader: () => import("./pages/account/profile"),
    roles: [UserRole.Administrator, UserRole.Recruiter]
  },
  security: {
    key: "/account/security",
    path: "/account/security",
    mustBeAuthenticated: true,
    loader: () => import("./pages/account/security"),
    roles: [UserRole.Administrator, UserRole.Recruiter]
  },
  accountEmails: {
    key: "/account/emails",
    path: "/account/emails",
    mustBeAuthenticated: true,
    loader: () => import("./pages/account/emails"),
    roles: [UserRole.Administrator, UserRole.Recruiter]
  },
  reporting: {
    key: "/reporting",
    path: "/reporting/(calls|sms|applications|connectivity|gps|battery)?",
    mustBeAuthenticated: true,
    loader: () => import("./pages/reporting"),
    roles: [UserRole.Administrator, UserRole.Customer, UserRole.CustomerTeam]
  }
};

export default routes;
