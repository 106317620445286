import { ILocales } from "./../../i18n";
export const SET_LOCALE = "SET_LOCALE";

export interface LanguageAction {
  type: typeof SET_LOCALE;
  payload: {
    locale: string;
  };
}

export interface LocaleState {
  locale: "en" | "fr";
}

export interface ILocalProps {
  children?: React.ReactElement;
  messages: ILocales;
}

export interface ILocalContext {
  locale: string;
  setLocale: (locale: string) => void;
  changeLocale: (locale: string) => void;
}
