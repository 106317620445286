import { FunctionComponent } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { useAuthContext } from "modules/auth/hooks";
import FullPageLoader from "components/full-page-loader";

interface Props extends RouteProps {
  roles?: string[];
}
const PrivateRoute: FunctionComponent<Props> = ({
  children,
  roles,
  ...rest
}) => {
  const { user, isFetchingAuthenticatedUser } = useAuthContext();

  // don't redirect if request is pending
  if (isFetchingAuthenticatedUser) return <FullPageLoader />;

  // redirect to /403 if user does not have the permission
  if (user && roles && !user.hasAnyRole(roles)) {
    return (
      <Redirect
        to={{
          pathname: "/403"
        }}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/authenticate",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
