import { Button, Result } from "antd";
import { ExceptionStatusType } from "antd/lib/result";
import { FunctionComponent, ReactElement } from "react";
import { Link } from "react-router-dom";

interface IProps {
  code?: ExceptionStatusType;
}

const ErrorPage: FunctionComponent<IProps> = ({ code = 404 }): ReactElement => {
  const errors = {
    "401": "Unauthorized",
    "403": "Forbidden Error",
    "404": "Not Found Error",
    "500": "Server Error"
  };

  return (
    <Result
      status={code}
      title={code}
      subTitle={errors[code]}
      extra={
        <Link to={"/"}>
          <Button type="primary">Go back Home</Button>
        </Link>
      }
    />
  );
};

export default ErrorPage;
