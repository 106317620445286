import Api from "services/api";
import { IAuthUser, ILoginRequestPayload, ILoginResponse } from "./types";

/**
 * Login a user with email and password to get an access token
 */
export const userLoginRequest = (payload: ILoginRequestPayload) =>
  Api.post<ILoginResponse>("dashboard/account/login", payload);

/**
 * Fetch currently authenticated user
 */
export const getAuthenticatedUserRequest = () =>
  Api.get<{ data: IAuthUser }>("dashboard/account/profile", {
    params: {
      with_roles: true,
      with_emails: true,
      with_permissions: true
    }
  }).then(({ data }) => data);

/**
 * Logout currently authenticated user
 */
export const userLogoutRequest = () =>
  Api.post<{ message: string }>("dashboard/account/logout");
