import Loadable from "react-loadable";
import { Route, Switch } from "react-router-dom";
import { FunctionComponent, ReactElement } from "react";

import { IRoutes } from "routes";
import ErrorPage from "pages/error";
import GuestRoute from "./GuestRoute";
import PrivateRoute from "./PrivateRoute";
import FullPageLoader from "components/full-page-loader";

interface IProps {
  routes: IRoutes;
}

const Router: FunctionComponent<IProps> = ({ routes }): ReactElement => {
  return (
    <Switch>
      {/* routes */}
      {Object.values(routes).map(
        ({ loader, mustBeAuthenticated, mustBeUnauthenticated, ...rest }) => {
          // load the view
          const View = Loadable({
            loader: loader,
            loading: FullPageLoader
          });

          // determine route based on authentication
          if (mustBeAuthenticated) {
            return (
              <PrivateRoute exact {...rest}>
                <View />
              </PrivateRoute>
            );
          }

          if (mustBeUnauthenticated) {
            return (
              <GuestRoute exact {...rest}>
                <View />
              </GuestRoute>
            );
          }
          return <Route exact {...rest} component={View} />;
        }
      )}

      {/* Errors routes */}
      {[403, 404, 500].map((code: number) => (
        <Route key={code} path={`/${code}`}>
          <ErrorPage code={code as any} />
        </Route>
      ))}

      {/* Not found page */}
      <Route path="*">
        <ErrorPage />
      </Route>
    </Switch>
  );
};

export default Router;
