import { SET_LOCALE, LanguageAction } from "./types";

export const initState =
  localStorage.getItem("locale") || window.navigator.language.slice(0, 2);

const locale = (state = initState, action: LanguageAction) => {
  switch (action?.type) {
    case SET_LOCALE:
      return action?.payload?.locale;
    default:
      return state;
  }
};

export default locale;
