import { FC, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import routes from "routes";
import Router from "modules/router";
import { AuthProvider } from "modules/auth";
import { LocaleProvider } from "modules/locale";
import { ErrorFallback } from "components/error";
import FullPageLoader from "components/full-page-loader";

import { ILocales } from "./i18n";

import "./app.less";
import "./overrides.css";

// https://react-query.tanstack.com/examples/simple
const queryClient = new QueryClient();

interface IProps {
  messages: ILocales;
}

const App: FC<IProps> = ({ messages }) => (
  <Suspense fallback={<FullPageLoader />}>
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />

        <BrowserRouter>
          <LocaleProvider messages={messages}>
            <AuthProvider>
              <Router routes={routes} />
            </AuthProvider>
          </LocaleProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ErrorBoundary>
  </Suspense>
);

export default App;
