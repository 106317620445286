import { createContext } from "react";

import { ILocalContext } from "./types";

/**
 * @see https://medium.com/@dai_shi/a-thought-on-react-context-default-value-fb3283cb5788
 */
export const LocaleContext = createContext<ILocalContext>({
  locale: localStorage.getItem("locale") || "en",
  changeLocale: () => {
    throw new Error("You need to wrap your application with <AuthProvider>.");
  },
  setLocale: () => {
    throw new Error("You need to wrap your application with <AuthProvider>.");
  }
});
