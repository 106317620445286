import { CloseCircleOutlined } from "@ant-design/icons";
import { Result, Typography } from "antd";
import { FunctionComponent, ReactElement } from "react";

interface IProps {
  error: Error;
}

const { Paragraph, Text } = Typography;

export const ErrorFallback: FunctionComponent<IProps> = ({
  error
}): ReactElement => {
  return (
    <Result
      status="error"
      title="Something went wrong"
      subTitle="Please check clear your cache and refresh your page."
    >
      <div>
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16
            }}
          >
            The content of your was the following:
          </Text>
        </Paragraph>
        <Paragraph>
          <CloseCircleOutlined style={{ color: "red" }} /> {error.message}
        </Paragraph>
      </div>
    </Result>
  );
};
