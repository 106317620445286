export enum UserRole {
  Administrator = "administrator",
  Customer = "customer",
  CustomerTeam = "customer-team",
  Recruiter = "recruiter"
}

export enum UserPermission {
  // Participants permissions
  ParticipantsList = "participants_list",
  ParticipantsView = "participants_view",
  ParticipantsCreate = "participants_create",
  ParticipantsUpdate = "participants_update",
  ParticipantsDelete = "participants_delete",
  ParticipantsBanning = "participants_banning",
  ParticipantsDownloadReport = "reports_data_categories_export",
  ParticipantsNotify = "participants_notify",
  ParticipantsImport = "participants_import",
  ParticipantsExport = "participants_export",

  // Customers permissions
  CustomersList = "customers_list",
  CustomersView = "customers_view",
  CustomersCreate = "customers_create",
  CustomersUpdate = "customers_update",
  CustomersDelete = "customers_delete",
  CustomersInvite = "customers_invite",
  CustomersBanning = "customers_banning",

  // Recruiters permissions
  RecruitersList = "recruiters_list",
  RecruitersView = "recruiters_view",
  RecruitersCreate = "recruiters_create",
  RecruitersUpdate = "recruiters_update",
  RecruitersDelete = "recruiters_delete",
  RecruitersInvite = "recruiters_invite",
  RecruitersBanning = "recruiters_banning",

  // Administrators permissions
  AdministratorsList = "administrators_list",
  AdministratorsView = "administrators_view",
  AdministratorsCreate = "administrators_create",
  AdministratorsUpdate = "administrators_update",
  AdministratorsDelete = "administrators_delete",

  // Characteristics permissions
  CharacteristicsList = "characteristics_list",
  CharacteristicsView = "characteristics_view",
  CharacteristicsCreate = "characteristics_create",
  CharacteristicsUpdate = "characteristics_update",
  CharacteristicsDelete = "characteristics_delete",

  //Campaigns permissions
  CampaignsList = "campaigns_list",
  CampaignsView = "campaigns_view",
  CampaignsCreate = "campaigns_create",
  CampaignsUpdate = "campaigns_update",
  CampaignsDelete = "campaigns_delete",

  // ApkReleases permissions
  ApkReleasesList = "apk_releases_list",
  ApkReleasesView = "apk_releases_view",
  ApkReleasesCreate = "apk_releases_create",
  ApkReleasesUpdate = "apk_releases_update",
  ApkReleasesDelete = "apk_releases_delete",

  // Reports permissions
  ReportsFilters = "reports_filters",
  ReportsCrosses = "reports_crosses",
  ReportsGeneral = "reports_general",
  ReportsCalls = "reports_calls",
  ReportsSmsMessages = "reports_sms_messages",
  ReportsApplications = "reports_applications",

  // Reports data Permissions
  ReportsDataCategoriesExport = "reports_data_categories_export"
}
