import {
  Children,
  FunctionComponent,
  ReactElement,
  useEffect,
  useReducer
} from "react";

import { IntlProvider } from "react-intl";

import localeReducer from "./reducers";
import { LocaleContext } from "./LocaleContext";
import { changeLocale, setLocale } from "./actions";
import { ILocalContext, ILocalProps } from "./types";

const cachedLocale = localStorage.getItem("locale") || "en";

/**
 * react component
 * @param {Object} props passed to this component
 */
export const LocaleProvider: FunctionComponent<ILocalProps> = ({
  messages,
  children
}): ReactElement => {
  const [locale, dispatch] = useReducer(localeReducer, cachedLocale);

  useEffect(() => {
    const supportedLanguages = Object.keys(messages);

    if (cachedLocale !== locale && supportedLanguages.includes(cachedLocale)) {
      dispatch(changeLocale(cachedLocale));
    }
  }, []); // eslint-disable-line

  const changeLocalDispatcher = (locale: string) => {
    dispatch(changeLocale(locale));
  };

  const setLocalDispatcher = (locale: string) => {
    dispatch(setLocale(locale));
  };

  const localProvider: ILocalContext = {
    locale,
    changeLocale: changeLocalDispatcher,
    setLocale: setLocalDispatcher
  };

  const localMessages = messages[locale as "en" | "fr" | "ar"];

  return (
    <LocaleContext.Provider value={localProvider}>
      <IntlProvider locale={locale} messages={localMessages} key={locale}>
        {Children.only(children)}
      </IntlProvider>
    </LocaleContext.Provider>
  );
};

export default LocaleProvider;
