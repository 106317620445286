/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 */
const enTranslationMessages = require("./locales/en.json");
const frTranslationMessages = require("./locales/fr.json");
const arTranslationMessages = require("./locales/ar.json");

export interface ILocales {
  en: Record<string, string>;
  fr: Record<string, string>;
  ar: Record<string, string>;
}

export const locales: ILocales = {
  en: enTranslationMessages,
  fr: frTranslationMessages,
  ar: arTranslationMessages
};
